import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";

import Breadscrum from "../../components/Breadscrum";



const OpZavetiInner = styled.div`
  width: calc(100% - 50px);
  margin: 0 auto;
  padding-bottom: 30px;
  max-width: 1150px;
  margin-top: 25px;

  @media (min-width: 900px) {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  h1 {
    font-family: NewKansas;
    font-size: 26px;
    margin-bottom: 40px;
    color: #000;
    margin-top: 20px;

    @media (min-width: 900px) {
      font-size: 30px;
    }
  }

  h2 {
    font-family: Visuelt-Medium;
    font-size: 22px;
    margin-bottom: 25px;
    margin-top: 40px;
    color: #000000;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 20px;
  }

  ol {
    margin-bottom: 20px;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }
`;


const ObchodniPodminkyZaveti = ({ data }) => {

  return (
    <Layout
      customCtaText={"Vytvořit parte"}
      customCtaLink={"/vytvorit-parte/"}
    >
      <SEO
        title="Zásady ochrany osobních údajů, GDPR"
        description="Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů..."
        image={"/parteOg.png"}
      />

      <OpZavetiInner>
        <Breadscrum
          visible={true}
          stranky={[
            {name: "Domů", url: "/"},
            {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
            {name: "Parte", url: "/parte/"},
            {name: "Zásady ochrany osobních údajů", url: "/parte/gdpr/"}
          ]}
        />
        <h1>Zásady ochrany osobních údajů</h1>
        <div class="podminkyInner">
        <p><strong>I.</strong></p>
        <p><strong>Základní ustanovení</strong></p>
        <ol>
        <li>Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „<strong>GDPR</strong>”) je Goodbye s.r.o., IČ: 08319588, se sídlem Na Kopci 164, Svojetice 251 62 (dále jen: „<strong>správce</strong>“).</li>
        <li>Kontaktní údaje správce jsou</li>
        </ol>
        <p><strong>adresa:</strong> Na Kopci 164, Svojetice 251 62</p>
        <p><strong>email:</strong> podpora@goodbye.cz</p>
        <p><strong>telefon:</strong> 315 558 136</p>
        <ol start="3">
        <li>Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.</li>
        <li>Správce nejmenoval pověřence pro ochranu osobních údajů.</li>
        </ol>
        <p> </p>
        <p><strong>II.</strong></p>
        <p><strong>Zdroje a kategorie zpracovávaných osobních údajů</strong></p>
        <ol>
        <li>Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní údaje, které správce získal na základě plnění Vaší objednávky.</li>
        <li>Správce zpracovává Vaše identifikační a kontaktní údaje a údaje nezbytné pro plnění smlouvy.<span class="Apple-converted-space"> </span></li>
        </ol>
        <p> </p>
        <p><strong>III.</strong></p>
        <p><strong>Zákonný důvod a účel zpracování osobních údajů</strong></p>
        <ol>
        <li>Zákonným důvodem zpracování osobních údajů je<span class="Apple-converted-space"> </span></li>
        </ol>
        <ol>
        <ol>
        <li>poskytování online služeb podle čl. 6 odst. 1 písm. b) GDPR,</li>
        <li>Váš souhlas se zpracováním pro účely poskytování přímého marketingu (zejména pro zasílání obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7 odst. 2 zákona č. 480/2004 Sb., o některých službách informační společnosti v případě, že nedošlo k objednávce zboží nebo služby.<span class="Apple-converted-space"> </span></li>
        </ol>
        </ol>
        <ol start="2">
        <li>Účelem zpracování osobních údajů je</li>
        </ol>
        <ol>
        <ol>
        <li>vyřízení Vaší objednávky a výkon práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem; při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení objednávky (jméno a adresa, kontakt), poskytnutí osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy, bez poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze strany správce plnit,</li>
        <li>zasílání obchodních sdělení a činění dalších marketingových aktivit.<span class="Apple-converted-space"> </span></li>
        </ol>
        </ol>
        <ol start="3">
        <li>Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu čl. 22 GDPR. S takovým zpracováním jste poskytl/a svůj výslovný souhlas.<span class="Apple-converted-space"> </span></li>
        </ol>
        <p> </p>
        <p><strong>IV.</strong></p>
        <p><strong>Doba uchovávání údajů</strong></p>
        <ol>
        <li>Správce uchovává osobní údaje<span class="Apple-converted-space"> </span></li>
        </ol>
        <ol>
        <ol>
        <li>po dobu nezbytnou k výkonu služby,</li>
        <li>po dobu, než je odvolán souhlas se zpracováním osobních údajů pro účely marketingu, nejdéle 5 let, jsou-li osobní údaje zpracovávány na základě souhlasu.<span class="Apple-converted-space"> </span></li>
        </ol>
        </ol>
        <ol start="2">
        <li>Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže.<span class="Apple-converted-space"> </span></li>
        </ol>
        <p> </p>
        <p><strong>V.</strong></p>
        <p><strong>Příjemci osobních údajů (subdodavatelé správce)</strong></p>
        <ol>
        <li>Příjemci osobních údajů jsou osoby<span class="Apple-converted-space"> </span></li>
        </ol>
        <ol>
        <ol>
        <li>podílející se na dodání zboží / služeb / realizaci plateb na základě smlouvy,<span class="Apple-converted-space"> </span></li>
        <li>podílející se na zajištění provozu služeb,</li>
        <li>zajišťující marketingové služby.</li>
        </ol>
        </ol>
        <ol start="2">
        <li>Správce nemá v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní organizaci. Příjemci osobních údajů ve třetích zemích jsou poskytovatelé mailingových služeb / cloudových služeb.<span class="Apple-converted-space"> </span></li>
        </ol>
        <p> </p>
        <p><strong>VI.</strong></p>
        <p><strong>Vaše práva</strong></p>
        <ol>
        <li>Za podmínek stanovených v GDPR máte<span class="Apple-converted-space"> </span></li>
        </ol>
        <ol>
        <ol>
        <li>právo na přístup ke svým osobním údajům dle čl. 15 GDPR,<span class="Apple-converted-space"> </span></li>
        <li>právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18 GDPR.<span class="Apple-converted-space"> </span></li>
        <li>právo na výmaz osobních údajů dle čl. 17 GDPR.<span class="Apple-converted-space"> </span></li>
        <li>právo vznést námitku proti zpracování dle čl. 21 GDPR a<span class="Apple-converted-space"> </span></li>
        <li>právo na přenositelnost údajů dle čl. 20 GDPR.<span class="Apple-converted-space"> </span></li>
        <li>právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo email správce uvedený v čl. III těchto podmínek.<span class="Apple-converted-space"> </span></li>
        </ol>
        </ol>
        <ol start="2">
        <li>Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.</li>
        </ol>
        <p> </p>
        <p><strong>VII.</strong></p>
        <p><strong>Podmínky zabezpečení osobních údajů</strong></p>
        <ol>
        <li>Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních údajů.</li>
        <li>Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů.<span class="Apple-converted-space"> </span></li>
        <li>Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.</li>
        </ol>
        <p> </p>
        <p><strong>VIII.</strong></p>
        <p><strong>Závěrečná ustanovení</strong></p>
        <ol>
        <li>Odesláním objednávky z internetového objednávkového formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</li>
        <li>S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře. Zaškrtnutím souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</li>
        <li>Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách, případně Vám zašle novou verzi těchto podmínek na e-mailovou adresu, kterou jste správci poskytl/a.</li>
        </ol>
        <p> </p>
        <p>Tyto podmínky nabývají účinnosti dnem 13.4.2021.</p>
          <p>Goodbye s.r.o.</p>
          <p>Jiří Štěpánek, jednatel</p>
        </div>
      </OpZavetiInner>
    </Layout>
  );
}



export default ObchodniPodminkyZaveti;

















